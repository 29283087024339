import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"

const CatalogItemPage = () => (
	<Layout>
		<Seo title="Bottle of Wine" />

		<h2>Bottle of Wine</h2>

		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST09 bottle of wine front.jpg"
			alt="Jesse Rivest - Bottle of Wine - cover art"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h3>Prerelease note</h3>
		<p>
			To be released on January 27, 2022.
		</p>

		<h3>Words from Jesse</h3>
		<p>
			Finally, I have released this song.
		</p>
		<p>
			I started writing it around 2009, when I was livin in Wellington, New Zealand.
			I had the verse and chorus melodies, plus a lot of lyric ideas already recorded in a notebook.
			I dedicated a notebook to this song so I could work on it in depth;
			I wrote out versions of lyrics, as well as paragraphs assessing and speculating what the song was about.
			I used a mandolin a lot to play out the melody and work out ideas.
			Ultimately, though, the song sat many, many years without much more progress made.
			I know that I really wanted this song to be great;
			I can tell you that putting that kind of pressure on something you're creating is not a great idea if you want to be productive!
		</p>
		<p>
			In 2015 I made some attempts to work out the rhythm of the song;
			I can see the files in my computer, but I'd say I didn't make great progress.
			I worked a bit more on it in 2016, trying to see how it would come together as a finalized song; I didn't get too far.
			In 2017 and 2018, however, I made a breakthrough: I nailed down the introduction and ending instrumental bits.
			I felt a new confidence in my understanding of music (thanks to having been studying for several years at Brasilia's public music school)
			and I felt adventurous; I could also come up with a more rich harmony to back these melodies.
			I would say I had the song finished and communicable by the end of 2018.
		</p>
		<p>
			It essentially has never been played live prior to being released; there are a few exceptions.
			I was practicing some of my repertoire at a cafe I frequented and liked at the time (2018, 2019), called Quintal F/508, and I'm sure I aired the song there.
			I also played it a couple times at some quiet gigs at Liberty Shopping (Mall) in 2019 and even the beginning of 2020.
			But it's safe to say that virtually nobody has heard this song...
			Except, I remember the very first time I played it live: it was at a <span className="italic">sarau</span> at Felipe's house in 2018.
			My girlfriend, Jane, was there, among a very warm and musical crowd of folks; I felt very excited to try out the song, and I felt it was very well received.
			I remember that moment very fondly as it was also possibly the first time Jane (who is now my partner) had seen me sing and play.
			She loved the song, and even though I started writing it a decade prior, it felt like the song was for her.
		</p>
		<p>
			In more recent years I dreamed of recording it, and I always had Misael Barros in mind on drums, as well as Oswaldo Amorim on upright bass.
			I had played with Misael a couple times in the past, loved to catch his stellar playing around town, and otherwise enjoyed his friendship—he was an obvious choice for a drummer.
			Oswaldo was one of the—if not the very—first musicians I met when I arrived in Brazil;
			he always had and has an encouraging energy about him and he was and is a spectacular player.
			When I sent the song to both of them, they both enthusiastically said they were in; I was very excited!
			Oswaldo did his part at home—playing fretless bass rather than upright—while I had Misael bring his kit over to my Little Bakery Studio.
			Although I produced this track, I let Misael and Oswaldo do as they desired—I didn't guide them much.
			Misael chose to not even listen to my rhythm guide—he wanted to bring what was in his head—and
			Oswaldo surprised me with a whole bunch of tasty extras that I loved and put into the mix.
			So clearly they contributed to the production of this single.
			My friend Murilo came by to take some photos for the front cover; I wrapped it all up and now I've sent it out to the world.
		</p>
		<p>
			I hope you enjoy <span className="italic">Bottle of Wine</span>; it is very special to me.
		</p>

		<h3>Track listing</h3>
		<ol>
			<li>
				Bottle of Wine (4:53)<br />
				T9314934537 - © Jesse Rivest 2019 (SOCAN/BMI, CMRRA)<br />
				CA-6P8-22-00001 - © Jesse Rivest 2022 (PPL)
			</li>
		</ol>
		<p>
			This recording: catalog number JRIVEST09, © Jesse Rivest 2022, All Rights Reserved<br />
			UPC/EAN: 0198001615191<br />
			Released: January 27, 2022
		</p>

		<h3>Credits</h3>
		<p>
			Produced, recorded, mixed, and mastered by Jesse Rivest at his Little Bakery Studio in Brasilia, DF, Brazil.<br />
			(Bass recorded at Oswaldo's house).<br />
			Song written and sung by Jesse Rivest.<br />
			Drums performed by Misael Barros.<br />
			Fretless bass performed by Oswaldo Amorim.<br />
			Electric guitar performed by Jesse Rivest.<br />
			Backing vocals performed by Jesse Rivest.<br />
			Album cover photography by Murilo Fabrino.<br />
			Album cover layout by Jesse Rivest.<br />
		</p>

		<h3>Special Thanks</h3>
		<p>
			Thanks to Jane Lino for being supportive, always.<br />
			Thanks to Oswaldo and Misael for their energy, time, and performances.<br />
			Thanks to Tico de Moraes for his ears and feedback.<br />
			Thanks to Gustavo Halfeld for his ears and feedback.
		</p>

		<h3>Extras</h3>

		<h4>Back cover</h4>
		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST09 bottle of wine back.png"
			alt="Jesse Rivest - Bottle of Wine - back cover"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h3 className="lyrics">Lyrics</h3>
		<div>
			<h4 className="lyrics__title">
				Bottle of Wine</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2019
			</h5>
			<p>
				Grapes on the vine, lustrous in the late sun<br />
				On the row, it's a process so slow<br />
				Passion and care, love in the inconstant<br />
				How it starts, every detail takes part
			</p>
			<p>
				As she walked in she set her scene<br />
				That slender sense that some things just can't be<br />
				Oooo, we passed the night amicably<br />
				And you still hum your song unsung<br />
				Oh, bottle of wine
			</p>
			<p>
				Bottle of wine, dormant yet expectant<br />
				On reserve, you're on special reserve<br />
				Tinted behold, deferred celebration<br />
				Inner glow, ripe and ready to flow
			</p>
			<p>
				When I meet her you will, too<br />
				She will shed her tannin colours off for you<br />
				Oooo, wipe off your dust, sparkle and shine!<br />
				For until then you will remain<br />
				Oh, bottle of wine
			</p>
			<p>
				When I meet her you will, too<br />
				She will shed her tannin colours off for you, yes for me, too<br />
				Oooo, wipe off your dust, sparkle and shine!<br />
				For until then you will remain<br />
				An unsung refrain<br />
				Oh, bottle of wine
			</p>
		</div>

		<p>
			<Link to="../">Go back to Music Catalog</Link>
		</p>
	</Layout>
);

export default CatalogItemPage;
